<template>
  <layout-login>
    <router-view />
  </layout-login>
</template>

<script>
import LayoutLogin from '@/@core/layouts/layout-login/LayoutClean.vue'

export default {
  components: {
    LayoutLogin,
  },
}
</script>
